import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Loading } from 'components/Loader';
import { TickIcon, ExclamationMark } from 'components/svgs';
import request from 'request';
import ErrorMessage from './reservation/components/ErrorMessage';
import SuccesfulEmailResentMessage from './reservation/components/SuccefulEmailResentMessage';
import { useNavigate } from 'react-router-dom';
import { resetSuccessfulMessage } from '../helpers';

const ContactEmailConfirmation = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  if (!searchParams.get('token') || !searchParams.get('id'))
    navigate('/error404');

  const checkEmailConfirmation = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/verify-email/`,
        {
          id: searchParams.get('id'),
          token: searchParams.get('token'),
        },
      );

      if (response.status === 200) {
        setEmailVerified(true);
      }
    } catch (err) {
      console.log(err);
      setEmailVerified(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailResend = async () => {
    try {
      if (emailSent) return;
      setErrorMessage('');
      await request.post(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/api/v1/contacts/${searchParams.get(
          'id',
        )}/send_email_confirmation_url/`,
      );
      setEmailSent(true);
      resetSuccessfulMessage(setEmailSent);
    } catch (error) {
      console.log(error);
      setErrorMessage(
        'Υπήρξε κάποιο πρόβλημα με την αποστολή του email επιβεβαίωσης',
      );
      setEmailSent(false);
    }
  };

  useEffect(() => {
    if (!emailVerified && !isLoading) {
      checkEmailConfirmation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-lg rounded-3xl bg-gray-50 px-4 py-10 text-center md:max-w-xl xl:max-w-3xl xl:px-14 2xl:px-20"
      >
        <h1 className="mb-6 text-2xl font-bold md:text-3xl lg:text-4xl">
          {isLoading
            ? ''
            : emailVerified
            ? 'Το email σας επιβεβαιώθηκε!'
            : 'Το email σας δεν επιβεβαιώθηκε'}
        </h1>
        {isLoading ? (
          <div className="mt-10 flex justify-center">
            <div className="flex">
              <Loading size={150} />
            </div>
          </div>
        ) : (
          <>
            {emailVerified ? (
              <div className="flex flex-col items-center gap-3">
                <p className="text-base lg:text-xl">
                  Το email σας επιβεβαιώθηκε!
                </p>
                <span className="text-sm lg:text-xl">
                  Μπορείτε να κλείσετε αυτή τη σελίδα και να συνεχίσετε στην
                  ολοκλήρωση των κρατήσεων σας.
                </span>
                <TickIcon className="mt-3 h-14 w-14 text-green-600" />
              </div>
            ) : (
              <div>
                <div className="mb-5 flex flex-col items-center justify-center gap-3">
                  <span className="text-base lg:text-xl">
                    Για να συνεχίσετε με την ολοκήρωση των κρατήσεων σας,
                    ακολουθήστε τον σύνδεσμο στο email που σας έχουμε
                    αποστείλει.
                  </span>
                  {errorMessage ? (
                    <ErrorMessage message={errorMessage} />
                  ) : emailSent ? (
                    <SuccesfulEmailResentMessage />
                  ) : (
                    <ExclamationMark className="h-12 w-12 text-red-600" />
                  )}
                </div>
                <button
                  onClick={handleEmailResend}
                  type="button"
                  className="inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 sm:px-6 sm:py-3.5"
                >
                  Επανάληψη αποστολής
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default ContactEmailConfirmation;
