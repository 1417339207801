import { Outlet } from 'react-router-dom';

import Stepper from './components/Stepper';
import InstructionsModal from './components/InstructionsModal';
import RemovalModal from './components/RemovalModal';
import AddAttachmentModal from './components/AddAttachmentModal';
import useStore from 'store';

export default function New() {
  const {
    isInstructionsModalOpen,
    setIsInstuctionsModalOpen,
    removalModalState,
    setRemovalModalState,
    addAttachmentModalState,
    setAddAttachmentModalState,
  } = useStore();

  return (
    <>
      <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
        <Stepper />

        <Outlet />
      </main>

      <InstructionsModal
        open={isInstructionsModalOpen}
        onClose={() => {
          setIsInstuctionsModalOpen(false);
        }}
      />

      <RemovalModal
        open={removalModalState.isOpen}
        appointment={removalModalState?.appointment!}
        onClose={() => {
          setRemovalModalState({ isOpen: false, appointment: undefined });
        }}
      />

      <AddAttachmentModal
        open={addAttachmentModalState.isOpen}
        onClose={() => {
          setAddAttachmentModalState({ isOpen: false, appointment: undefined });
        }}
        appointment={addAttachmentModalState.appointment!}
      />
    </>
  );
}
