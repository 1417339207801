import request from 'request';
import { useQuery, useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import {
  AppointmentType,
  VisitorInfoFormType,
  Slots,
  StatusType,
  InsuranceProvidersType,
  UploadAppointmentAttachment,
  VisitorsIDs,
  ServicesDataType,
  PolicyType,
  AvailabilityHours,
} from 'types';
import getToken from 'utils/getToken';
import useStoreServices from 'storeServices';
import { isEmpty } from 'lodash';

export function useStatuses() {
  return useQuery({
    queryKey: ['statuses'],
    queryFn: async () => {
      const { data } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/statuses/`,
      );

      return data as StatusType[];
    },
    onError: (error) => {
      console.log(error);
    },
  });
}

const getDefaultFinalStatus = (
  appointment: AppointmentType,
  statusesList: StatusType[],
) => {
  let defaultStatus;
  const appointmentPoint = appointment.point;

  const service_customization_for_point =
    appointment.service?.service_customizations?.find(
      (service_customization) =>
        service_customization.point === appointmentPoint?.id,
    );

  const final_status =
    service_customization_for_point?.final_online_status ??
    appointment.service?.final_online_status?.id;

  defaultStatus =
    statusesList?.find((item) => item.id === final_status) ??
    statusesList?.find((item) => item.is_final_for_online_reservation) ??
    statusesList?.find((item) => item.status_type === 'BOOKED');

  return defaultStatus as StatusType;
};

export function useServicesData() {
  return useQuery({
    queryKey: ['serviceGrpData'],
    queryFn: async () => {
      const { data: serviceGrp } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/service-groups/`,
      );
      const { data: services } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/services/`,
      );
      const { data: points } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/points/`,
      );

      const groupedData = {
        serviceGrp: [...serviceGrp.results],
        services: [...services],
        points: [...points.results],
      };

      return groupedData as ServicesDataType;
    },
    onError: (error) => {
      console.log(error);
    },
    enabled: true,
  });
}

export function useAvailabilityHours(
  /** service group in state */
  appointment?: AppointmentType,
  selectedStartDate?: Date,
  selectedEndDate?: Date,
) {
  return useQuery(
    [
      'availabilityHours',
      appointment?.point?.id,
      appointment?.service?.id,
      appointment?.serviceGrp?.id,
      selectedStartDate,
      selectedEndDate,
    ],
    async () => {
      if (!appointment?.service?.id) {
        return [];
      }
      const { data } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/availability/hours/`,
        {
          params: {
            service_id: appointment?.service?.id,
            point_id: appointment?.point?.id,
            start: format(selectedStartDate!, 'yyyy-MM-dd'),
            end: format(selectedEndDate!, 'yyyy-MM-dd'),
          },
        },
      );
      return data as AvailabilityHours;
    },
    {
      onError: (error: any) => {
        console.log(error);
      },
      staleTime: 0,
      cacheTime: 0,
      enabled: Boolean(appointment?.point?.id && appointment?.service?.id),
    },
  );
}

export function useAvailableSlots(
  /** service group in state */
  appointment?: AppointmentType,
  selectedDate?: Date,
) {
  return useQuery(
    [
      'availableSlots',
      appointment?.point?.id,
      appointment?.service?.id,
      appointment?.serviceGrp?.id,
      selectedDate,
    ],
    async () => {
      if (!appointment?.service?.id) {
        return [];
      }
      const { data } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/slots/`,
        {
          params: {
            service_id: appointment?.service?.id,
            point_id: appointment?.point?.id,
            start:
              format(selectedDate!, 'yyyy-MM-dd') ??
              format(appointment?.start!, 'yyyy-MM-dd'),
            end:
              format(selectedDate!, 'yyyy-MM-dd') ??
              format(appointment?.end!, 'yyyy-MM-dd'),
          },
        },
      );
      return data as Slots[];
    },
    {
      onError: (error: any) => {
        console.log(error);
      },
      staleTime: 0,
      cacheTime: 0,
      enabled: Boolean(appointment?.point?.id && appointment?.service?.id),
    },
  );
}

// get insurance providers
export function useInsuranceProviders() {
  return useQuery({
    queryKey: ['insuranceproviders'],
    queryFn: async () => {
      const { data } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/insurance-providers/`,
      );

      return data as InsuranceProvidersType[];
    },
    onError: (error) => {
      console.log(error);
    },
    enabled: isEmpty(useStoreServices().availableInsuranceProviders),
  });
}

export function useArrivalPolicies() {
  return useQuery({
    queryKey: ['arrivalPolicies'],
    queryFn: async () => {
      const { data } = await request.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/arrival-policies/`,
      );

      return data as PolicyType[];
    },
    onError: (error) => {
      console.log(error);
    },
    enabled: isEmpty(useStoreServices().availableArrivalPolicies),
  });
}

/** check appointment availability */
const checkAvailability = async (appointment: AppointmentType) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/v1/reservations/`;
  let params = {
    service_id: appointment?.service?.id,
    point_id: appointment?.point?.id,
    date: appointment?.start && format(appointment?.start, 'yyyy-MM-dd'),
    start_time: appointment?.time,
    status_id: appointment?.status?.id,
  };

  const token = getToken('token');
  const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

  const { data } = await request.post(url, params, config);
  return data;
};
export const useCheckAvailability = () => {
  return useMutation(checkAvailability);
};

// NOT USED
/** Reserve appointments */
const reserveAppointments = async (appoinmtmentData: {
  appointments: AppointmentType[];
  visitorInfo: VisitorInfoFormType;
}) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_API_URL}/api/v1/reservations/`,
    {
      status_id: 1,
    },
  );

  return data;
};

// NOT USED
export const useReserveAppointments = () => {
  return useMutation(reserveAppointments);
};

/** confirm appointments */
const confirmAppointments = async (appoinmtmentData: {
  visitorInfo: VisitorInfoFormType;
  appointments: AppointmentType[];
}) => {
  const { visitorInfo, appointments } = appoinmtmentData;
  const formatName = (first: string, last: string) => `${first} ${last}`;

  const createContact = async (body: object) => {
    const response = await request.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/contacts/`,
      body,
    );
    return response.data.id;
  };

  const primaryContactBody = {
    name: formatName(visitorInfo.first_name, visitorInfo.last_name),
    email: visitorInfo.email,
    phone: visitorInfo.phone_number,
    notes: visitorInfo.notes,
    has_foreign_ssrn: visitorInfo.has_foreign_ssrn,
    ssrn: visitorInfo.ssrn,
    insurance_provider: visitorInfo.insurance_provider?.id,
    tos_accepted: visitorInfo.tos_accepted,
    email_notifications_accepted: visitorInfo.email_notifications_accepted,
    sms_notifications_accepted: visitorInfo.sms_notifications_accepted,
  };

  const primaryContactId: string = await createContact(primaryContactBody);
  let secondaryContactId: string | null = null;

  if (visitorInfo.escort) {
    const secondaryContactBody = {
      name: formatName(
        visitorInfo.escort_first_name,
        visitorInfo.escort_last_name,
      ),
      phone: visitorInfo.escort_phone,
      tos_accepted: visitorInfo.tos_accepted,
      email_notifications_accepted: visitorInfo.email_notifications_accepted,
      sms_notifications_accepted: visitorInfo.sms_notifications_accepted,
    };
    secondaryContactId = await createContact(secondaryContactBody);
  }

  const finalizeReservationContact = async (appointment: AppointmentType) => {
    try {
      const { data } = await request.put(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/reservations/${appointment.id}/`,
        {
          primary_contact: primaryContactId,
          secondary_contact: secondaryContactId,
          insurance_provider: visitorInfo.insurance_provider?.id,
          token: appointment.token,
        },
      );
      return data;
    } catch (error: any) {
      // eslint-disable-next-line no-throw-literal
      throw { appointment: appointment, error };
    }
  };

  await Promise.all(
    appointments.map(async (appointment) => {
      try {
        return await finalizeReservationContact(appointment);
      } catch (error: any) {
        // eslint-disable-next-line no-throw-literal
        throw { id: appointment.id, ...error };
      }
    }),
  );

  const visitorsInfo: VisitorsIDs = {
    primaryContact: primaryContactId,
    secondaryContact: secondaryContactId,
  };

  return visitorsInfo;
};

/** confirms that appointments are not expired
 * @returns status: 'CONFIRMED' || 'EXPIRED'
 */
export const useConfirmAppointments = () => {
  return useMutation(confirmAppointments);
};

/** Finalize appointments */
const finalizeAppointments = async (appointmentData: {
  appointments: AppointmentType[];
  ids: VisitorsIDs;
}) => {
  const { appointments } = appointmentData;
  const { data: statusList } = await request.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/v1/statuses/`,
  );

  const finalizeReservation = async (appointment: AppointmentType) => {
    const default_final_status = getDefaultFinalStatus(appointment, statusList);

    const { data } = await request.put(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/reservations/${appointment.id}/`,
      {
        token: appointment.token,
        status_id: default_final_status.id,
      },
    );

    return data;
  };

  const promises = appointments.map(finalizeReservation);
  return Promise.all(promises);
};

export const useFinalizeAppointments = () => {
  return useMutation({
    mutationFn: finalizeAppointments,
  });
};

/** cancel single appointment */
const cancelAppointment = async (appointment: AppointmentType) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_API_URL}/api/v1/reservations/${appointment.id}/`,
    {
      token: appointment.token,
      status_id: 4,
    },
  );

  return data;
};
export const useCancelAppointment = () => {
  return useMutation(cancelAppointment);
};

/** add attachments to appointment */
const addAppointmentAttachment = async (
  attachment: UploadAppointmentAttachment,
) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/v1/reservations/${attachment.appointment.id}/attachments/`;
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  const { data } = await request.post(url, attachment.attachment, config);

  !attachment.appointment.attachments &&
    (attachment.appointment.attachments = []);

  return data;
};
export const useAddAppointmentAttachment = () => {
  return useMutation(addAppointmentAttachment);
};
