import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Loading } from 'components/Loader';
import { ExclamationMark, NextStepIcon, TickIcon } from 'components/svgs';
import ErrorMessage from './reservation/components/ErrorMessage';
import { resetSuccessfulMessage } from 'helpers';
import SuccesfulEmailResentMessage from './reservation/components/SuccefulEmailResentMessage';

const UserEmailConfirmation = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  if (!searchParams.get('token') || !searchParams.get('id'))
    navigate('/error404');

  const checkEmailConfirmation = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/verify-email/`,
        {
          id: searchParams.get('id'),
          token: searchParams.get('token'),
        },
      );

      if (response.status === 200) {
        setShowSuccessMessage(true);
      }
    } catch (err) {
      console.log(err);
      setShowSuccessMessage(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleEmailResend = async () => {
    try {
      if (emailSent) return;
      const { status } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/send-verification-email/`,
        { id: searchParams.get('id') },
      );

      if (status === 200) {
        setEmailSent((prev) => !prev);
        setErrorMessage('');
        resetSuccessfulMessage(setEmailSent);
      } else
        setErrorMessage('Υπήρξε κάποιο πρόβλημα με την αποστολή του email.');
    } catch (error) {
      console.log(error);
      setErrorMessage('Υπήρξε κάποιο πρόβλημα με την αποστολή του email.');
    }
  };

  useEffect(() => {
    if (!showSuccessMessage && !isLoading) {
      checkEmailConfirmation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-lg rounded-3xl bg-gray-50 px-4 py-10 text-center md:max-w-xl xl:max-w-3xl xl:px-14 2xl:px-20"
      >
        <h1 className="mb-6 text-2xl font-bold md:text-3xl lg:text-4xl">
          {isLoading
            ? ''
            : showSuccessMessage
            ? 'Το email σας επιβεβαιώθηκε!'
            : 'Το email σας δεν επιβεβαιώθηκε'}
        </h1>
        {isLoading ? (
          <div className="mt-10 flex justify-center">
            <div className="flex">
              <Loading size={150} />
            </div>
          </div>
        ) : (
          <>
            {showSuccessMessage ? (
              <div className="flex flex-col items-center gap-3">
                <p className="text-base lg:text-xl"></p>
                <span className="text-sm lg:text-xl">
                  Μεταβείτε στη σελίδα σύνδεσης για να εισέλθετε στην εφαρμογή
                  με το email σας και τον κωδικό πρόσβασης που ορίσατε κατά την
                  εγγραφή.
                </span>
                <TickIcon className="h-14 w-14 text-green-600" />
                <button
                  onClick={handleLogin}
                  type="button"
                  className="mt-4 flex items-center gap-3 rounded-full border border-primary bg-primary px-4 py-2.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 sm:px-6 sm:py-3.5"
                >
                  Συνδεθείτε
                  <NextStepIcon className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <div className="">
                <div className="mb-5 flex flex-col items-center justify-center gap-3">
                  <span className="text-base lg:text-xl">
                    Για να ολοκληρωθεί η δημιουργία του λογαριασμού σας,
                    ακολουθήστε τον σύνδεσμο στο email που σας έχουμε
                    αποστείλει.
                  </span>
                  {errorMessage ? (
                    <ErrorMessage message={errorMessage} />
                  ) : emailSent ? (
                    <SuccesfulEmailResentMessage />
                  ) : (
                    <ExclamationMark className="h-12 w-12 text-red-600" />
                  )}
                </div>
                <button
                  onClick={handleEmailResend}
                  type="button"
                  className="inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 sm:px-6 sm:py-3.5"
                >
                  Επανάληψη αποστολής
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default UserEmailConfirmation;
